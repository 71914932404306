import { EnvConfigInterface } from './types';
import { Environment } from './enums';

export const EnvConfig: EnvConfigInterface = {
  ENV: (process.env.ENV as Environment) || Environment.prod,
  CDN_URL: process.env.CDN_URL || '',
  PUBLISHER_PROJECT_ID: process.env.PUBLISHER_PROJECT_ID || '130226',
  GTM_TAG: process.env.GTM_TAG || 'GTM-PXDK5D4',
  PS_SANDBOX: process.env.PS_SANDBOX === 'true',

  // API
  API_URL: process.env.API_URL || 'https://babka.com/api',
  GATEWAY_API_URL: process.env.GATEWAY_API_URL || 'https://wallet.xsolla.com/api',
  LOGIN_URL: process.env.LOGIN_URL || 'https://login.xsolla.com/api',
  WALLET_API_URL: process.env.WALLET_API_URL || 'https://wallet.babka.com/api',
  MERCHANT_URL: process.env.MERCHANT_URL || 'https://api.xsolla.com/merchant',
  XSOLLA_MONEY_GATEWAY_API_URL: process.env.XSOLLA_MONEY_GATEWAY_API_URL || 'https://money-api.xsolla.com/api',

  // Login
  LOGIN_WIDGET_URL: process.env.LOGIN_WIDGET_URL || 'https://login-widget.xsolla.com',
  LOGIN_WIDGET_PROJECT_ID: process.env.LOGIN_WIDGET_PROJECT_ID || '89c824d7-9b5d-11eb-bc85-42010aa80004',
  LOGIN_WIDGET_CLIENT_ID: parseInt(process.env.LOGIN_WIDGET_CLIENT_ID || '4660', 10),

  // Xsolla Consent
  XSOLLA_CONSENT_URL: process.env.XSOLLA_CONSENT_URL || 'https://id.xsolla.com/assets/apps/consent.js',

  // Chat
  CHAT_URL: process.env.CHAT_URL || '',
  CHAT_SCRIPT_URL: process.env.CHAT_SCRIPT_URL || 'https://cdn.chat.xsolla.com/widget/livechat-iframe.js',

  // AB Experiments
  AB_API_URL: process.env.AB_API_URL || 'https://ab.xsolla.com/v2',
  AB_PRODUCT_NAME: process.env.AB_PRODUCT_NAME || 'babka-account',
  AB_LOYALTY_PROJECT_ID: process.env.AB_LOYALTY_PROJECT_ID || '',

  // Analytics
  ANALYTICS_API_URL: process.env.ANALYTICS_API_URL || 'https://datagather.xsolla.com',

  // Feature Flags
  CONFIG_CAT_SDK_KEY: process.env.CONFIG_CAT_SDK_KEY || '',

  // Remotes
  XSOLLA_PAY_CARDS_REMOTE_URL: process.env.XSOLLA_PAY_CARDS_REMOTE_URL || 'https://mf.babka.com/pay-cards-account',

  VIRTUAL_CURRENCY_REMOTE_URL: process.env.VIRTUAL_CURRENCY_REMOTE_URL || 'https://virtual-currency.vercel.app',

  // Backpack
  BACKPACK_REMOTE_URL: process.env.BACKPACK_REMOTE_URL || 'https://mf.babka.com/backpack-account',

  // Parental Control
  PARENTAL_CONTROL_REMOTE_URL: process.env.PARENTAL_CONTROL_REMOTE_URL || 'https://mf.babka.com/parent-control-account',

  // Xsolla Money
  XSOLLA_MONEY_REMOTE_URL: process.env.XSOLLA_MONEY_REMOTE_URL || 'https://xsolla-money.vercel.app',

  // Dev options
  get SHOW_MODULE_TEMPLATE() {
    return this.ENV === Environment.dev && process.env.SHOW_MODULE_TEMPLATE === 'true';
  },
  MODULE_TEMPLATE_REMOTE_URL: process.env.MODULE_TEMPLATE_REMOTE_URL || 'http://localhost:8080'
};
